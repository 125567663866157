const getActivity = (activityName) => {
    let translatedActivityName = activityName;
    
    if (activityName.indexOf('Adult Intermediate') > -1 || activityName.indexOf('Alpine MAX6 (5 & above)') > -1 || activityName.indexOf('Alpine Adult Group (5') > -1) {
        translatedActivityName = 'Ski5-8';
    }
    else if (activityName.indexOf('Adult New Skier') > -1) {
        translatedActivityName = 'ANS';
    }
    else if (activityName.indexOf('Snowboard') > -1) {
        translatedActivityName = 'SB';
    }
    else {
        translatedActivityName = 'Ski';
    }

    return translatedActivityName;
};

export default getActivity;
