import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Dropzone from 'react-dropzone';
import { parseString as parseXmlString } from 'xml2js';
import { get, find, sortBy } from 'lodash';

import Grid from 'react-bootstrap/lib/Grid';
import Row from 'react-bootstrap/lib/Row';
import Col from 'react-bootstrap/lib/Col';

import 'react-select/dist/react-select.css';

import 'font-awesome/css/font-awesome.css';

import Nav from './nav.js';

import getActivity from '../utils/get-activity';

class Weekend extends Component {
    handleDrop = (acceptedFiles) => {
        acceptedFiles.forEach(file => {
            const reader = new FileReader();
            reader.onload = () => {
                const fileAsText = reader.result;
                parseXmlString(fileAsText, (err, result) => {
                    if (err) console.log(err);
                    const dataPath = 'Report.Table1[0].Detail_Collection[0].Detail';
                    const data = get(result, dataPath)
                    if (data) {
                        const activities = [];
                        data.forEach(record => {
                            const name = getActivity(record.$.InstructorActivity_1);
                            const timestamp = moment(record.$.ActivityStartDate_2);
                            const date = timestamp.format('ddd, D MMM YYYY');
                            const time = timestamp.format('LT');
                            let activity = find(activities, { name });
                            if (!activity) {
                                activity = {
                                    name,
                                    lessons: []
                                };
                                activities.push(activity);
                            }
                            let lesson = find(activity.lessons, { timestamp } )
                            if (!lesson) {
                                lesson = {
                                    timestamp,
                                    date,
                                    time,
                                    instructors: []
                                };
                                activity.lessons.push(lesson);
                                activity.lessons = sortBy(activity.lessons, 'timestamp');
                            }
                            lesson.instructors.push(record.$.InstructorName);
                        });
                        const sortedActivities = sortBy(activities, ['name']);
                        this.props.actions.setActivities(sortedActivities);
                    } else {
                        console.log(`${dataPath} not found!`);
                    }
                });
            };
            reader.onabort = () => console.log('file reading was aborted');
            reader.onerror = () => console.log('file reading has failed');
            reader.readAsText(file);
        });
    };

    appendBlankRows(rowsArray, numberRows, numberCols) {
        const cols = [];

        for (let i = 0; i < numberCols; i++)
        {
            cols.push(
                <td>&nbsp;</td>
            );
        }

        for (let i = 0; i < numberRows; i++)
        {
            rowsArray.push(
                <tr>
                    {cols}
                </tr>);
        }
    };

    renderTableForActivity(displayActivity, time) {
        let rows = [];
        let displayTable = false;

        const fontSize = this.state && this.state.fontSize ? this.state.fontSize : '18px';

        this.props.activities && this.props.activities.map((activity, i) => {
            activity.lessons.map((lesson, j) => {
                lesson.instructors.map((instructor, k) => {
                    if ((activity.name === displayActivity) && (!time || lesson.time === time)) {
                        displayTable = true;
                        rows.push(
                            <tr>
                                <td style={{width: '30px'}}>{k + 1}</td>
                                <td style={{fontWeight: 'bolder', fontSize}}>{instructor}</td>
                                <td style={{whiteSpace: 'nowrap'}}>{lesson.time}</td>
                                <td>&nbsp;</td>
                                <td>&nbsp;</td>
                                <td>&nbsp;</td>
                            </tr>);
                    }
                    return null;
                });
                if ((activity.name === displayActivity) && (!time || lesson.time === time)) {
                    this.appendBlankRows(rows, 5, 6);
                }
                return null;
            });
            return null;
        });

        return (
            <table style={{display: displayTable ? 'block' : 'none'}}>
                <tbody>
                    <tr>
                        <td colSpan="5"><h2>{displayActivity}</h2></td>
                    </tr>
                    <tr>
                        <th style={{width: '30px'}}>&nbsp;</th>
                        <th>Instructor Name</th>
                        <th>Time</th>
                        <th>Lev</th>
                        <th>Tot</th>
                        <th>Ret</th>
                    </tr>
                    {rows}
                </tbody>
            </table>
        );
    }

    renderTableForActivitityTimes(displayActivity, times) {
        const tables = [];

        for(let i = 0; i < times.length; i++) {
            tables.push(this.renderTableForActivity(displayActivity, times[i]));
        }

        return tables;
    }

    renderTableForTime(time, displayActivity) {
        let rows = [];
        let displayTable = false;

        const fontSize = this.state && this.state.fontSize ? this.state.fontSize : '18px';

        this.props.activities && this.props.activities.map((activity, i) => {
            activity.lessons.map((lesson, j) => {
                lesson.instructors.map((instructor, k) => {
                    if ((lesson.time === time) && (!displayActivity || activity.name === displayActivity)) {
                        displayTable = true;
                        rows.push(
                            <tr>
                                <td style={{width: '30px'}}>{k + 1}</td>
                                <td style={{fontWeight: 'bolder', fontSize}}>{instructor}</td>
                                <td>{activity.name}</td>
                                <td>&nbsp;</td>
                                <td>&nbsp;</td>
                                <td>&nbsp;</td>
                            </tr>);
                    }
                    return null;
                });
                if ((lesson.time === time) && (!displayActivity || activity.name === displayActivity)) {
                    this.appendBlankRows(rows, 5, 6);
                }
                return null;
            });
            return null;
        });

        return (
            <table style={{display: displayTable ? 'block' : 'none'}}>
                <tbody>
                    <tr>
                        <td colSpan="5"><h2>{time}</h2></td>
                    </tr>
                    <tr>
                        <th style={{width: '30px'}}>&nbsp;</th>
                        <th>Instructor Name</th>
                        <th>Activity</th>
                        <th>Lev</th>
                        <th>Tot</th>
                        <th>Ret</th>
                    </tr>
                    {rows}
                </tbody>
            </table>
        );
    }

    renderTableForTimeActivities(time, displayActivities) {
        const tables = [];

        for(let i = 0; i < displayActivities.length; i++) {
            tables.push(this.renderTableForTime(time, displayActivities[i]));
        }

        return tables;
    }

    handleFontSizeChange = (changeEvent) => {
        this.setState({
            fontSize: changeEvent.target.value
        });
    }

    getFirstActivity() {
        return get(this.props.activities, '[0].name') === 'SB' ? 'SB' : 'Ski';
    }

    componentDidMount() {
        this.setState({
            groupByActivity: true,
            groupByTime: false,
            fontSize: '18px'
        });
    }

    render() {
        const date = get(this.props.activities, '[0].lessons[0].date');
        const firstActivity = this.getFirstActivity();
        const fontSize = this.state && this.state.fontSize ? this.state.fontSize : '18px';
        return (
            <Grid>
                <Row className="no-print">
                    <Col xs={12}>
                        <Nav {...this.props} />
                    </Col>
                </Row>
                <Row className="no-print">
                    <Col xs={12}>
                        <h1>Weekend Groups</h1>
                    </Col>
                </Row>
                <Row className="no-print">
                    <Col xs={12}>
                        <Dropzone onDrop={this.handleDrop} accept="text/xml" style={{ width: '400px', height: '50px', backgroundColor: 'lightgray' }}>
                            <p>Drop the XML file here or click to browse to the file to upload</p>
                        </Dropzone>
                    </Col>
                </Row>
                <Row className="font-size no-print">
                    <Col xs={12}>
                        <label>Instructor Name Font size</label>
                        <label>
                            <input type="radio" value="14px" checked={fontSize === '14px'} onChange={this.handleFontSizeChange} />&nbsp;Small
                        </label>
                        <label>
                            <input type="radio" value="18px" checked={fontSize === '18px'} onChange={this.handleFontSizeChange} />&nbsp;Medium
                        </label>
                        <label>
                            <input type="radio" value="24px" checked={fontSize === '24px'} onChange={this.handleFontSizeChange} />&nbsp;Large
                        </label>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}><h1>{date}</h1></Col>
                </Row>
                <Row>
                    <Col xs={6}>
                        {this.renderTableForActivity(firstActivity, '9:15 AM')}
                        {this.renderTableForActivity('Ski5-8', '9:15 AM')}
                    </Col>
                    <Col xs={6}>
                        {this.renderTableForActivity(firstActivity, '11:20 AM')}
                        {this.renderTableForActivity('Ski5-8', '11:20 AM')}
                    </Col>
                </Row>
                <Row className="page-break">
                    <Col xs={12}><h1>{date}</h1></Col>
                </Row>
                <Row>
                    <Col xs={6}>
                        {this.renderTableForActivity(firstActivity, '2:00 PM')}
                        {this.renderTableForActivity('Ski5-8', '2:00 PM')}
                    </Col>
                </Row>
                <Row className="page-break">
                    <Col xs={12}><h1>{date}</h1></Col>
                </Row>
                <Row>
                    <Col xs={6}>{this.renderTableForActivity('ANS', '9:15 AM')}</Col>
                    <Col xs={6}>{this.renderTableForActivitityTimes('ANS', ['11:20 AM', '2:00 PM'])}</Col>
                </Row>
            </Grid>
        );
    }
}

Weekend.propTypes = {
    actions: PropTypes.object.isRequired,
    activities: PropTypes.array
};

export default Weekend;
