import React from 'react';
import { Route, Switch } from 'react-router-dom';

import App from '../containers/app';
import Availability from '../containers/availability';
import Weekend from '../containers/weekend';
import Privates from '../containers/privates';
import PrivatesCompact from '../containers/privates-compact';
// import NotFound from './not-found';

export default () => (
  <Switch>
    <Route exact path="/availability" component={Availability} />
    <Route exact path="/weekend" component={Weekend} />
    <Route exact path="/privates" component={Privates} />
    <Route exact path="/privates-compact" component={PrivatesCompact} />
    <Route exact path="*" component={App} />

    {/* <Route component={NotFound} /> */}
  </Switch>
);
