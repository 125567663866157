import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Dropzone from 'react-dropzone';
import { parseString as parseXmlString } from 'xml2js';
import { get, orderBy } from 'lodash';

import Grid from 'react-bootstrap/lib/Grid';
import Row from 'react-bootstrap/lib/Row';
import Col from 'react-bootstrap/lib/Col';

import 'react-select/dist/react-select.css';

import 'font-awesome/css/font-awesome.css';

import Nav from './nav.js';

class Availability extends Component {
    handleDrop = (acceptedFiles) => {
        acceptedFiles.forEach(file => {
            const reader = new FileReader();
            reader.onload = () => {
                const fileAsText = reader.result;
                parseXmlString(fileAsText, (err, result) => {
                    if (err) console.log(err);
                    const dataPath = 'Report.Table1[0].Detail_Collection[0].Detail';
                    const data = get(result, dataPath)
                    if (data) {
                        const reserves = [];
                        data.forEach(record => {
                            reserves.push({
                                instructorName: record.$.InstructorName_1,
                                startTimestamp: moment(`${record.$.textbox10}+10:00`),
                                startTime: moment(`${record.$.textbox10}+10:00`).format('LT'),
                                endTime: moment(`${record.$.textbox10}+10:00`).add(parseInt(record.$.ActivityDuration, 10), 'h').format('LT'),
                                duration: record.$.ActivityDuration,
                                activity: record.$.Activity,
                                instructorStatus: record.$.InstructorStatus,
                                rank: record.$.InstructorRank_1,
                                primaryDiscipline: record.$.LessonDiscipline,
                                level: record.$.MaxLevel,
                                lessonComment: record.$.textbox4
                            });
                        });
                        const sortedReserves = orderBy(reserves, ['startTimestamp', 'activity', 'duration', 'instructorName'], ['asc', 'asc', 'desc','asc']);
                        this.props.actions.setReserves(sortedReserves);
                    } else {
                        console.log(`${dataPath} not found!`);
                    }
                });
            };
            reader.onabort = () => console.log('file reading was aborted');
            reader.onerror = () => console.log('file reading has failed');
            reader.readAsText(file);
        });
    };

    getFontStyle = (primaryDiscipline) => primaryDiscipline.indexOf('SB') > -1 ? 'italic': 'normal';

    getTextDecoration = (primaryDiscipline) => primaryDiscipline.indexOf('Children') > -1 || primaryDiscipline.indexOf('3-6') > -1 ? 'line-through' : 'none';

    render() {
        const reportDate = this.props.reserves && this.props.reserves.length ? this.props.reserves[0].startTimestamp : new Date().toISOString();
        const displayDate = moment(reportDate).format('ddd, D MMM YYYY');
        const rows = [];
        this.props.reserves && this.props.reserves.map((reserve, i) => {
            rows.push(
                <tr key={i}>
                    <td style={{whiteSpace: 'nowrap', fontStyle: this.getFontStyle(reserve.primaryDiscipline), textDecoration: this.getTextDecoration(reserve.primaryDiscipline)}}>{reserve.startTime}</td>
                    <td style={{whiteSpace: 'nowrap', fontStyle: this.getFontStyle(reserve.primaryDiscipline), textDecoration: this.getTextDecoration(reserve.primaryDiscipline)}}>{reserve.endTime}</td>
                    <td style={{whiteSpace: 'nowrap', fontStyle: this.getFontStyle(reserve.primaryDiscipline), textDecoration: this.getTextDecoration(reserve.primaryDiscipline)}}>{reserve.instructorName}</td>
                    <td style={{whiteSpace: 'nowrap', fontStyle: this.getFontStyle(reserve.primaryDiscipline), textDecoration: this.getTextDecoration(reserve.primaryDiscipline)}}>{reserve.duration}</td>
                    <td style={{whiteSpace: 'nowrap', fontStyle: this.getFontStyle(reserve.primaryDiscipline), textDecoration: this.getTextDecoration(reserve.primaryDiscipline)}}>{reserve.activity}</td>
                    <td style={{whiteSpace: 'nowrap', fontStyle: this.getFontStyle(reserve.primaryDiscipline), textDecoration: this.getTextDecoration(reserve.primaryDiscipline)}}>{reserve.instructorStatus}</td>
                    <td style={{whiteSpace: 'nowrap', fontStyle: this.getFontStyle(reserve.primaryDiscipline), textDecoration: this.getTextDecoration(reserve.primaryDiscipline)}}>{reserve.rank}</td>
                    <td style={{whiteSpace: 'nowrap', fontStyle: this.getFontStyle(reserve.primaryDiscipline), textDecoration: this.getTextDecoration(reserve.primaryDiscipline)}}>{reserve.primaryDiscipline}</td>
                    <td style={{whiteSpace: 'nowrap', fontStyle: this.getFontStyle(reserve.primaryDiscipline), textDecoration: this.getTextDecoration(reserve.primaryDiscipline)}}>{reserve.level}</td>
                    <td style={{whiteSpace: 'nowrap', fontStyle: this.getFontStyle(reserve.primaryDiscipline), textDecoration: this.getTextDecoration(reserve.primaryDiscipline)}}>{reserve.lessonComment}</td>
                </tr>);
            return null;
        });
        return (
            <Grid>
                <Row className="no-print">
                    <Col xs={12}>
                        <Nav {...this.props} />
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <h1>Availability - {displayDate}</h1>
                    </Col>
                </Row>
                <Row className="no-print">
                    <Col xs={12}>
                        <Dropzone onDrop={this.handleDrop} accept="text/xml" style={{ width: '400px', height: '50px', backgroundColor: 'lightgray' }}>
                            <p>Drop the XML file here or click to browse to the file to upload</p>
                        </Dropzone>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <table>
                            <tbody>
                                {rows && rows.length > 0 &&
                                    <tr>
                                        <th style={{whiteSpace: 'nowrap'}}>Start Time</th>
                                        <th style={{whiteSpace: 'nowrap'}}>End Time</th>
                                        <th>Instructor Name</th>
                                        <th>Duration (hours)</th>
                                        <th>Activity</th>
                                        <th>Instructor Status</th>
                                        <th>Rank</th>
                                        <th style={{whiteSpace: 'nowrap'}}>Primary Discipline</th>
                                        <th>Level</th>
                                        <th>Lesson Comment</th>
                                    </tr>
                                }
                                {rows}
                            </tbody>
                        </table>
                    </Col>
                </Row>
            </Grid>
        );
    }
}

Availability.propTypes = {
    actions: PropTypes.object.isRequired,
    reserves: PropTypes.array
};

export default Availability;
