import * as types from './action-types';

// export function requestTopic(host, topic) {
//     return {
//         api: {
//             endpoint: `${host}/news/${topic}`,
//             types: [types.REQUEST_TOPIC, types.RECEIVE_TOPIC, types.ERROR]
//         },
//         topic
//     };
// }

// export function requestArticle(host, url) {
//     return {
//         api: {
//             endpoint: `${host}/scraper?url=${encodeURIComponent(url)}`,
//             types: [types.REQUEST_ARTICLE, types.RECEIVE_ARTICLE, types.ERROR]
//         },
//         url
//     };
// }

// export function removeImage() {
//     return {
//         type: types.REMOVE_IMAGE
//     };
// }

export function setActivities(activities) {
    return {
        type: types.SET_ACTIVITIES,
        activities
    };
}

export function setReserves(reserves) {
    return {
        type: types.SET_RESERVES,
        reserves
    };
}