export const ERROR = 'ERROR';

// export const REQUEST_TOPIC = 'REQUEST_TOPIC';
// export const RECEIVE_TOPIC = 'RECEIVE_TOPIC';

// export const REQUEST_ARTICLE = 'REQUEST_ARTICLE';
// export const RECEIVE_ARTICLE = 'RECEIVE_ARTICLE';

// export const REMOVE_IMAGE = 'REMOVE_IMAGE';

export const SET_ACTIVITIES = 'SET_ACTIVITIES';
export const SET_RESERVES = 'SET_RESERVES';
