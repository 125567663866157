import React from 'react';
import { Link } from 'react-router-dom';

import Navbar from 'react-bootstrap/lib/Navbar';
import Nav from 'react-bootstrap/lib/Nav';
import NavItem from 'react-bootstrap/lib/NavItem';

const NewsNav = () => (
    <Navbar inverse fixedTop collapseOnSelect>
        <Navbar.Header>
            <Navbar.Brand>
                <img src="/white-144px.png" alt="Thredbo Schedule Formatter" /><Link to="/">Thredbo Schedule Formatter</Link>
            </Navbar.Brand>
            <Navbar.Toggle />
        </Navbar.Header>
        <Navbar.Collapse>
            <Nav pullRight>
                <NavItem href="/">
                    <span className='glyphicon glyphicon-home'></span>&nbsp;&nbsp;Group Lessons
                </NavItem>
                <NavItem href="/availability">
                    <span className='glyphicon glyphicon-th-list'></span>&nbsp;&nbsp;Availability
                </NavItem>
                <NavItem href="/weekend">
                    <span className='glyphicon glyphicon-th-list'></span>&nbsp;&nbsp;Weekend Groups
                </NavItem>
                <NavItem href="/privates">
                    <span className='glyphicon glyphicon-th-list'></span>&nbsp;&nbsp;Private Lesson Schedule
                </NavItem>
                <NavItem href="/privates-compact">
                    <span className='glyphicon glyphicon-th-list'></span>&nbsp;&nbsp;Private Lesson Schedule - Compact
                </NavItem>
            </Nav>
        </Navbar.Collapse>
    </Navbar>
);

export default NewsNav
