import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Dropzone from 'react-dropzone';
import { parseString as parseXmlString } from 'xml2js';
import { get, orderBy } from 'lodash';

import Grid from 'react-bootstrap/lib/Grid';
import Row from 'react-bootstrap/lib/Row';
import Col from 'react-bootstrap/lib/Col';

import 'react-select/dist/react-select.css';

import 'font-awesome/css/font-awesome.css';

import Nav from './nav.js';

class Privates extends Component {
    handleDrop = (acceptedFiles) => {
        acceptedFiles.forEach(file => {
            const reader = new FileReader();
            reader.onload = () => {
                const fileAsText = reader.result;
                parseXmlString(fileAsText, (err, result) => {
                    if (err) console.log(err);
                    const dataPath = 'Report.table1[0].Detail_Collection[0].Detail';
                    const data = get(result, dataPath)
                    console.log('data', data);
                    if (data) {
                        const reserves = [];
                        data.forEach(record => {
                            reserves.push({
                                customer: record.$.CustomerName_1,
                                tranId: record.$.textbox15,
                                startTimestamp: moment(`${record.$.textbox12}+10:00`),
                                startTime: moment(`${record.$.textbox12}+10:00`).format('LT'),
                                level: record.$.SkillLevel,
                                assignment: record.$.InstructorAssignment,
                                startLocation: record.$.StartLessonLocation,
                                product: record.$.ProductHeader,
                                paymentMethod: record.$.PaymentMethod,
                                instructor: record.$.InstructorName,
                                isChild: record.$.ProductHeader.toLowerCase().indexOf('child') > -1
                            });
                        });
                        const sortedReserves = orderBy(reserves, ['startTimestamp', 'customer'], ['asc', 'asc']);
                        this.props.actions.setReserves(sortedReserves);
                    } else {
                        console.log(`${dataPath} not found!`);
                    }
                });
            };
            reader.onabort = () => console.log('file reading was aborted');
            reader.onerror = () => console.log('file reading has failed');
            reader.readAsText(file);
        });
    };

    getStartLocation = (startLocation) => {
        let location = startLocation;
        let trucatedLocation = '';

        let spaceIndex = location.indexOf(' ');
        if (spaceIndex > -1) {
            trucatedLocation = location.substr(0, spaceIndex + 1);
            location = location.substr(spaceIndex + 1);
        } else {
            trucatedLocation = location; 
        }

        spaceIndex = location.indexOf(' ');
        if (spaceIndex > -1) {
            trucatedLocation += location.substr(0, spaceIndex);
            location = location.substr(spaceIndex + 1);
        }
        
        return trucatedLocation;
    };

    handleGroupByChange = (changeEvent) => {
        this.setState({
            groupByAge: changeEvent.target.value === 'age',
            groupByNone: changeEvent.target.value === 'none',
        });
    }

    componentDidMount() {
        this.setState({
            groupByAge: true,
            groupByNone: false,
        });
    }

    pushRow(reserve, i) {
        return (
            <tr key={i}>
                <td style={{whiteSpace: 'nowrap', padding: '10px', fontWeight: 'bold', fontSize: '16px', fontStyle: reserve.product.indexOf('Board') > -1 ? 'italic': 'normal'}}>{reserve.customer}</td>
                <td style={{whiteSpace: 'nowrap', padding: '10px', fontStyle: reserve.product.indexOf('Board') > -1 ? 'italic': 'normal'}}>{reserve.instructor}</td>
                <td style={{whiteSpace: 'nowrap', padding: '10px', fontStyle: reserve.product.indexOf('Board') > -1 ? 'italic': 'normal' }}>{reserve.startTime}</td>
                <td style={{whiteSpace: 'nowrap', padding: '10px', textAlign: 'center', fontStyle: reserve.product.indexOf('Board') > -1 ? 'italic': 'normal'}}>{reserve.level}</td>
                <td style={{whiteSpace: 'nowrap', padding: '10px', fontWeight: reserve.assignment.indexOf('Private') > -1 ? 'bold' : 'normal', fontStyle: reserve.product.indexOf('Board') > -1 ? 'italic': 'normal'}}>{reserve.assignment}</td>
                <td style={{whiteSpace: 'nowrap', padding: '10px', fontStyle: reserve.product.indexOf('Board') > -1 ? 'italic': 'normal'}}>{this.getStartLocation(reserve.startLocation)}</td>
                <td style={{whiteSpace: 'nowrap', padding: '10px', fontStyle: reserve.product.indexOf('Board') > -1 ? 'italic': 'normal'}}>{reserve.product}</td>
            </tr>
        );
    }

    render() {
        const groupByAge = this.state ? this.state.groupByAge : true;
        const groupByNone = this.state ? this.state.groupByNone : false;
        const reportDate = this.props.reserves && this.props.reserves.length ? this.props.reserves[0].startTimestamp : new Date().toISOString();
        const displayDateTime = moment(reportDate).format('ddd, D MMM YYYY h:mm A');
        const rows = [];
        const rowsAdult = [];
        const rowsChild = [];
        this.props.reserves && this.props.reserves.map((reserve, i) => {
            rows.push(this.pushRow(reserve, i));
            if (reserve.isChild) {
                rowsChild.push(this.pushRow(reserve, i));
            } else {
                rowsAdult.push(this.pushRow(reserve, i));
            }
            return null;
        });
        return (
            <Grid>
                <Row className="no-print">
                    <Col xs={12}>
                        <Nav {...this.props} />
                    </Col>
                </Row>
                <Row>
                    <Col xs={2}>
                        <img src="https://www.thredbo.com.au/wp-content/themes/thredbo-2017/dist/images/logos/main-logo.png" width="100px" alt="Logo" />
                    </Col>                   
                    <Col xs={10}>
                        <h1 style={{fontSize: '20px', paddingTop: '30px'}}>Private Lesson Schedule - {displayDateTime}</h1>
                    </Col>
                </Row>
                <Row className="no-print">
                    <Col xs={12}>
                        <Dropzone onDrop={this.handleDrop} accept="text/xml" style={{ width: '400px', height: '50px', backgroundColor: 'lightgray' }}>
                            <p>Drop the XML file here or click to browse to the file to upload</p>
                        </Dropzone>
                    </Col>
                </Row>
                <Row className="group-by no-print">
                    <Col xs={12}>
                        <label>Group by</label>
                        <label>
                            <input type="radio" value="age" checked={groupByAge} onChange={this.handleGroupByChange} />&nbsp;Adult&nbsp;/&nbsp;Child&nbsp;separate
                        </label>
                        <label>
                            <input type="radio" value="none" checked={groupByNone} onChange={this.handleGroupByChange} />&nbsp;Adult&nbsp;/&nbsp;Child&nbsp;together
                        </label>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        { groupByAge &&
                            <>
                                <table style={{ marginTop: "30px" }}>
                                    <tbody>
                                    {rows && rows.length > 0 &&
                                        <tr>
                                        <th style={{ padding: "10px" }}>Customer (Adult)</th>
                                        <th style={{ padding: "10px" }}>Instructor</th>
                                        <th style={{ padding: "10px" }}>Time</th>
                                        <th style={{ padding: "10px" }}>Level</th>
                                        <th style={{ padding: "10px" }}>Assignment</th>
                                        <th style={{ padding: "10px" }}>Start Location</th>
                                        <th style={{ padding: "10px" }}>Product</th>
                                        </tr>}
                                    {rowsAdult}
                                    </tbody>
                                </table>
                                <table style={{ marginTop: "30px" }}>
                                    <tbody>
                                    {rows && rows.length > 0 &&
                                        <tr>
                                        <th style={{ padding: "10px" }}>Customer (Child)</th>
                                        <th style={{ padding: "10px" }}>Instructor</th>
                                        <th style={{ padding: "10px" }}>Time</th>
                                        <th style={{ padding: "10px" }}>Level</th>
                                        <th style={{ padding: "10px" }}>Assignment</th>
                                        <th style={{ padding: "10px" }}>Start Location</th>
                                        <th style={{ padding: "10px" }}>Product</th>
                                        </tr>}
                                    {rowsChild}
                                    </tbody>
                                </table>
                            </>
                        }
                        { groupByNone &&
                                    <table style={{marginTop: '30px'}}>
                                        <tbody>
                                            {rows && rows.length > 0 &&
                                                <tr>
                                                    <th style={{padding: '10px'}}>Customer</th>
                                                    <th style={{padding: '10px'}}>Instructor</th>
                                                    <th style={{padding: '10px'}}>Time</th>
                                                    <th style={{padding: '10px'}}>Level</th>
                                                    <th style={{padding: '10px'}}>Assignment</th>
                                                    <th style={{padding: '10px'}}>Start Location</th>
                                                    <th style={{padding: '10px'}}>Product</th>
                                                </tr>
                                            }
                                            {rows}
                                        </tbody>
                                    </table>
                        }
                    </Col>
                </Row>
            </Grid>
        );
    }
}

Privates.propTypes = {
    actions: PropTypes.object.isRequired,
    reserves: PropTypes.array
};

export default Privates;
